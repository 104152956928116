<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row>
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-group"
          inline
        >
          <template v-slot:after-heading>
            <div class="text-h3 font-weight-light">
              User Dashboard
            </div>
          </template>
          <v-data-table
            :items="users"
            :headers="headers"
            item-key="key"
            :search="search"
            class="elevation-1"
            :sort-desc="true"
            calculate-widths
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name.trim() ? item.name : 'No Name Provided' }}
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="activeUser?.key!==item.key"
                color="primary"
                @click="initiateSession(item)"
              >
                Select User
              </v-btn>
              <v-btn
                v-if="activeUser?.key===item.key"
                color="primary"
                @click="destroySession()"
              >
                Logout
              </v-btn>
            </template>
            <template v-slot:no-data>
              No users available.
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-overlay v-if="loading">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
  import { firebase } from '@firebase/app'
  import { mapState } from 'vuex'

  export default {
    data () {
      return {
        search: '',
        users: [],
        loading: true,
      }
    },
    computed: {
      ...mapState(['activeUser', 'user']),
      headers () {
        return [
          {
            text: 'Name',
            sortable: true,
            value: 'name'
          }, {
            text: 'Email',
            sortable: true,
            value: 'email'
          },
          { text: 'Actions', value: 'actions', sortable: false },

        ]
      }

    },
    watch: {

      async user (value, oldValue) {
        if (value.id) {
          await this.getUsers()
        }
      }
    },
    async created () {
      await this.getUsers()
    },

    methods: {
      async getUsers () {
        if (!this.user.id) { return }
        try {
          const { data: { response: { users } } } = await firebase.functions().httpsCallable('getUsers')({ userId: this.user.id })
          this.users = users?.map(({ key, name, email }) => ({ key, name: name !== '' ? name : email, email })).filter(({ name, email }) => name && email)
        } catch (e) {
          this.$store.commit('setError', e.message)
        } finally {
          this.loading = false
        }
      },
      initiateSession (user) {
        this.$store.commit('setActiveUser', user)
        this.$router.push('/userapplications')
      },
      destroySession () {
        this.$store.commit('setActiveUser', null)
      }
    }
  }
</script>

<style lang="scss">
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.style-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}

.style-position {
  z-index: 5000 !important;
}

</style>
